<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <div class="container-fluid">
          <!-- Default box -->
          <section class="content-header">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="mb-0">Grupos De Autorizacion Casos Soporte</h5>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item">
                      <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active">Casos Soporte</li>
                    <li class="breadcrumb-item active">
                      Grupos De Autorizacion Soporte
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <section class="content">
            <div class="container-fluid">
              <div class="card">
                <div class="card-header pt-2 pb-2">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="btn-group float-right">
                        <button
                          type="button"
                          class="btn btn-primary"
                          data-toggle="modal"
                          data-target="#myModal"
                          @click="abrirModal('Crear', null)"
                        >
                          <i class="fas fa-plus"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body table-responsive p-0">
                  <table
                    style="font-size: 0.85em"
                    class="
                      table table-bordered table-striped table-hover table-sm
                      text-nowrap
                    "
                  >
                    <thead class="text-center">
                      <tr>
                        <th>Id</th>
                        <th>
                          Nombre
                        </th>
                        <th>Estado</th>
                        <th>Usuarios</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="grupo in casosAuto.data" :key="grupo.id">
                        <td style="width: 50px">{{ grupo.id }}</td>
                        <td>{{ grupo.nombre }}</td>
                        <td style="width: 100px">{{ grupo.nEstado }}</td>
                        <td style="width: 100px">
                          <div
                            v-for="usuario in grupo.det_grupo_casos_auto"
                            :key="usuario.id"
                          >
                            <span class="badge badge-secondary">{{
                              usuario.usuario.name
                            }}</span>
                          </div>
                        </td>
                        <td style="width: 50px">
                          <div class="btn-group float-right">
                            <button
                              type="button"
                              class="btn btn-sm bg-navy"
                              data-toggle="modal"
                              data-target="#myModal"
                              @click="abrirModal('Editar', grupo.id)"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              v-if="
                                $store.getters.can(
                                  'admin.casosMesaAyuda.delete'
                                )
                              "
                              type="button"
                              class="btn btn-sm btn-danger"
                              @click="destroy(grupo.id)"
                            >
                              <i class="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="card-footer">
                  <div class="float-left" v-if="casosAuto.total">
                    <p>
                      Mostrando del <b>{{ casosAuto.from }}</b> al
                      <b>{{ casosAuto.to }}</b> de un total:
                      <b>{{ casosAuto.total }}</b> Registros
                    </p>
                  </div>
                  <div class="float-left" v-else>
                    <p>
                      <span class="badge badge-danger">
                        No hay registros para mostrar
                      </span>
                    </p>
                  </div>
                  <pagination
                    :data="casosAuto"
                    @pagination-change-page="getIndex"
                    :limit="5"
                    align="right"
                  ></pagination>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <!-- Modal -->
      <div
        class="modal fade"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header bg-frontera-top-left pt-2 pb-2">
              <h4 class="modal-title text-white" id="myModalLabel">
                {{ modal.title }}Grupo De Autorizacion
              </h4>
              <button
                type="button"
                class="close text-white"
                data-dismiss="modal"
                aria-label="Close"
                @click="limpiarModal()"
                ref="closeModal1"
                id="close-modal"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="nombre">Nombre Grupo De Autorizacion</label>
                  <input
                    type="text"
                    maxlength="100"
                    class="form-control form-control-sm"
                    id="nombre"
                    v-model="form.nombre"
                    :class="$v.form.nombre.$invalid ? 'is-invalid' : 'is-valid'"
                  />
                </div>
                <div class="form-group col-md-6">
                  <label for="estado">Estado</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.estado"
                    :class="$v.form.estado.$invalid ? 'is-invalid' : 'is-valid'"
                  >
                    <option value="">Seleccione</option>
                    <option
                      v-for="nEstado in listasForms.estados"
                      :key="nEstado.numeracion"
                      :value="nEstado.numeracion"
                    >
                      {{ nEstado.descripcion }}
                    </option>
                  </select>
                </div>
                <div
                  class="form-group col-md-12"
                  v-if="modal.accion == 'Editar'"
                >
                  <label for="estado">Usuario</label>
                  <v-select
                    v-model="users"
                    placeholder="Usuario"
                    label="name"
                    :options="listasForms.usuarios"
                    @input="selectUsers()"
                    multiple
                  >
                  </v-select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Salir
              </button>
              <button type="button" class="btn btn-primary" @click="save()">
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
export default {
  name: "CasosSoporteAutorizaciones",
  components: { vSelect, pagination },
  data() {
    return {
      cargando: false,
      casosAuto: {},
      estado: [],
      filtros: {
        usuario_id: null,
      },
      listasForms: {
        usuarios: [],
        estados: [],
        nombre: [],
      },
      form: {
        estado: null,
        users_id: null,
        nombre: null,
      },
      users: [],
      modal: {
        title: "",
        accion: "",
      },
    };
  },

  validations() {
    return {
      form: {
        estado: {
          required,
        },
        nombre: {
          required,
        },
      },
    };
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/admin/casosSoporteAutorizaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.casosAuto = response.data;
          this.cargando = false;
        });
    },

    getEstado() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getUsuarios() {
      await axios.get("/api/admin/usuarios/lista").then((response) => {
        this.listasForms.usuarios = response.data;
      });
    },

    selectUsers() {
      this.form.users = [];
      if (this.users) {
        this.form.users_id = this.users.map((u) => u.id);
      }
    },

    abrirModal(accion, id) {
      /*    this.limpiarModal(); */
      this.getEstado();
      this.modal.accion = accion;
      this.modal.title = accion + " Caso ";
      if (accion == "Editar") {
        this.show(id);
      } else {
        this.form = {};
      }
    },

    limpiarModal() {
      this.form = {
        estado: null,
        users_id: null,
        nombre: null,
      };
      this.users = [];
    },

    show(idGrupo) {
      axios
        .get("api/admin/casosSoporteAutorizaciones/show/" + idGrupo)
        .then((response) => {
          this.form = response.data;
          let users = [];
          if (
            this.form.det_grupo_casos_auto &&
            this.form.det_grupo_casos_auto.length > 0
          ) {
            for (let i = 0; i < this.form.det_grupo_casos_auto.length; i++) {
              users.push({
                id: this.form.det_grupo_casos_auto[i].user_id,
                name: this.form.det_grupo_casos_auto[i].usuario.name,
              });
            }
            this.users = users;
            this.form.users_id = this.users.map((u) => u.id);
          }
        });
    },

    destroy(idGrupo) {
      this.$swal({
        title: "Esta seguro de eliminar este Item?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios
            .delete("/api/admin/casosSoporteAutorizaciones/" + idGrupo)
            .then((response) => {
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "Se elimino el item exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async save() {
      await axios
        .post("api/admin/casosSoporteAutorizaciones", this.form)
        .then((response) => {
          this.modal.accion = "Editar";
          this.modal.title = "Editar Caso ";
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "Se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vualeva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
  },

  async mounted() {
    await this.getUsuarios();
    await this.getEstado();
    await this.getIndex();
  },
};
</script>
